// requires _button.scss
$ac-card-base-size: 1rem;
$ac-card-line-height: 1.4;
$ac-card-base-color: $body-font-color;
$ac-card-base-color-inverted: $white;
$ac-card-background-color: $light-gray;
$ac-card-background-color-inverted: $black;
$ac-card-content-padding: 1rem;
$ac-card-title-size: 1.4em;
$ac-card-title-line-height: 1.2;

.ac-card {
    @include component-reset(get-color(primary), $ac-card-base-size);
    text-transform: initial;

    &__wrapper {
        display: block;
        transition: opacity 0.25s linear;
    }

    &__badge {
        margin-bottom: 0.5em;

        + .ac-card__heading {
            margin-top: 0;
        }
    }

    &__heading {
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 1.125em;
        font-weight: bold;
        color: currentColor;
    }

    &__description {
        @include multiline-truncate($ac-card-base-size, $ac-card-line-height, 4);
        margin-bottom: 1em;
        color: $body-font-color;
    }

    &__link {
        font-size: 0.875em;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;

        &:hover {
            color: currentColor;
        }
    }

    &__link-icon {
        display: none;
    }

    &__image {
        img {
            width: 100%;
        }

        .ac-mega-menu & {
            display: none;

            @include breakpoint(xlarge) {
                display: block;
            }
        }
    }

    &__file-details {
        font-weight: bold;

        dt {
            @include element-invisible;
        }

        dd {
            display: inline-block;
        }
    }

    &__file-size {
        &:before {
            content: ' - ';
        }
    }


    &__file-type {
        text-transform: uppercase;
    }

    &--bordered {
        .ac-card__wrapper {
            position: relative;
            padding-bottom: 1rem;
            background-color: $white;
            box-shadow: $global-shadow-close;
            transition: opacity 0.25s linear;

            &:hover {
                opacity: 0.9;
            }

            .ac-custom-grid__item & {
                position: static;
            }
        }

        .ac-card__description {
            @include multiline-truncate($ac-card-base-size, $ac-card-line-height, 3);
            margin-bottom: 0.5em;
        }

        .ac-card__heading,
        .ac-card__description,
        .ac-card__link,
        .ac-card__link-list,
        .ac-card__file-details {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    &--emphasis {
        .ac-card__wrapper {
            padding-bottom: 2.5rem;
        }

        .ac-card__heading {
            font-size: 1.25em;
        }

        .ac-card__link {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            padding: rem-calc(7) 1em rem-calc(9);
            background-color: $dark-gray;
            transition: 0.2s background-color $global-ease;
            color: $white;
            text-align: center;

            &:active,
            &:hover {
                background-color: lighten($dark-gray, 10%);
                color: $white;
            }

            :hover > &,
            :active > & {
                background-color: lighten($dark-gray, 10%);
            }
        }

        .ac-card__link-text {
            display: inline-block;
            color: currentColor;
        }

        .ac-card__link-icon {
            display: inline-block;
            margin-left: 0.5em;
            font-size: 1.2em;
            line-height: 1;
        }
    }

    &--background-image {
        .ac-card__wrapper {
            position: relative;
            background: transparent;
        }

        .ac-card__image {
            opacity: 0.7;
        }

        .ac-card__content {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10000;
        }
    }

    &--horizontal {
        padding-bottom: 0;

        .ac-card__wrapper {
            @include xy-grid();
            @include flex-align(justify, stretch);
            padding: 0 !important;
        }

        .ac-card__image {
            @include xy-cell(100%, false);

            @include breakpoint(medium) {
                @include xy-cell(50%, false);
            }

            @supports (object-fit: cover) {
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .ac-card__content {
            @include xy-cell(auto);
            padding-top: 1em;
            padding-bottom: 1em;
        }

        .ac-card__description {
            @include multiline-truncate($ac-card-base-size, $ac-card-line-height, 3);
            margin-bottom: 0.5em;
        }

        .ac-card__heading,
        .ac-card__description,
        .ac-card__link {
            padding-right: 0;
            padding-left: 0;
        }

        .ac-card__heading {
            margin-top: 0;
        }
    }

    &--mega-menu {
        .ac-card__heading {
            font-size: 1.25em;
            font-weight: normal;
            color: $body-font-color;
        }
    }

    &--inverted {
        @include component-reset($ac-card-base-color-inverted, $ac-card-base-size);

        .ac-card__wrapper {
            background-color: $ac-card-background-color-inverted;
        }
    }

    &--angle-effect {
        .ac-card__image {
            position: relative;
            overflow: hidden;

            &:after {
                @include pseudo;
                right: 0;
                bottom: 0;
                width: 50%;
                height: 200%;
                opacity: 0.6;
                transform: rotate(23deg);
                transform-origin: 0 100%;
                background-color: get-color(secondary);

            }
        }
    }

    &--no-ellipse {
        .ac-card__heading,
        .ac-card__description {
            display: block;
            height: auto;
        }

        .ac-card__link {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }


    &--search-result {
        padding-bottom: 2em;
        border-bottom: 1px solid $medium-gray;
    }

    &--has-link-list {
        .ac-card__wrapper {
            &:hover {
                opacity: 1;
            }
        }

        &#{&} {
            .ac-card__link {
                :hover > &,
                :active > & {
                    background-color: $dark-gray;
                }

                &:active,
                &:hover {
                    background-color: lighten($dark-gray, 10%);
                    color: $white;
                }
            }
        }
    }
}

.ac-social-links {
    @include xy-grid();
    @include flex-align(center, middle);
    width: 100%;

    @include breakpoint(large) {
        @include flex-align(left, middle);
    }

    a {
        @include xy-cell(shrink, false);
        display: block;
        margin-right: 1em;
        color: $dark-gray;

        &:active,
        &:hover {
            color: darken($dark-gray, 10%);
        }
    }

    svg {
        width: rem-calc(35);
        height: rem-calc(35);
    }
}

@mixin ac-button($ac-button-bg-color: get-color(secondary), $ac-button-text-color: get-color(primary) ) {
    position: relative;
    display: inline-block;
    margin-top: 0.5em;
    margin-right: 2em;
    margin-left: rem-calc(15);
    font-size: 0.875em;
    font-weight: bold;
    line-height: 1.185;
    color: $ac-button-text-color;
    cursor: pointer;

    &:hover,
    &:active {
        span {
            &:first-child {
                background-color: darken($ac-button-bg-color, 10%);

                &:before,
                &:after {
                    border-color: transparent darken($ac-button-bg-color, 10%) transparent transparent;
                }
            }
        }
    }

    &[disabled] {
        span {

            &:first-child {
                background-color: $medium-gray;

                &:before,
                &:after {
                    border-color: transparent $medium-gray transparent transparent;
                }
            }
        }
    }

    span {
        &:first-child {
            display: inline-block;
            padding: rem-calc(4) 1em rem-calc(6);
            background-color: get-color(secondary);
            transition: 0.2s background-color $global-ease;
            color: $black;
            text-transform: uppercase;

            &:before,
            &:after {
                @include pseudo;
                top: 0;
                width: 0;
                height: 0;
                border-width: 0 rem-calc(15) rem-calc(24) 0;
                border-style: solid;
                border-color: transparent get-color(secondary) transparent transparent;
                transition: 0.2s border-color $global-ease;
            }

            &:before {
                left: rem-calc(-15);
            }

            &:after {
                right: rem-calc(-15);
                transform: rotate(180deg);
            }
        }

        + span {
            position: absolute;
            top: 0;
            right: -1.75em;
            font-size: 1.4em;
            line-height: 1;
        }
    }
}

.ac-button {
    @include ac-button;

    + .ac-button {
        margin-left: 3em;
    }

    &--offset {
        margin-left: -1.75em;
    }
}

// @include font-face('Proxima Nova', '../fonts/Proxima-Nova-Th-Thin', 300, normal);
// @include font-face('Proxima Nova', '../fonts/Proxima-Nova-Alt-Rg-Regular', 400, normal);
// @include font-face('Proxima Nova', '../fonts/Proxima-Nova-Alt-Bi-Regular', 400, italic);
// @include font-face('Proxima Nova', '../fonts/Proxima-Nova-Alt-Rg-Bold', 700, normal);

@include font-face("Montserrat", "../fonts/Montserrat-Regular", 400, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Italic", 400, italic);
@include font-face("Montserrat", "../fonts/Montserrat-SemiBold", 700, normal);
@include font-face(
    "Montserrat",
    "../fonts/Montserrat-SemiBold-Italic",
    700,
    italic
);

legend {
    font-family: $header-font-family;
    font-size: 1.5em;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
}

blockquote {
    font-size: 1.2em;
    font-style: italic;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

h1,
h2,
h3 {
    font-weight: 400;
}

select {
    line-height: 1.2;
}

.help-text,
.optional-text {
    color: darken($medium-gray, 30%);
}

.svg-icon {
    vertical-align: sub;
    max-width: 1em;
    max-height: 1em;
}

.callout {
    &.success {
        color: $white;
    }

    &.validation {
        @include callout-style(get-color(alert));
    }

    &.info {
        @include callout-style(get-color(alert));
    }
}

$ac-contact-base-size: 1rem;
$ac-contact-line-height: 1.2;

$ac-contact-heading-size: 1.2rem;
$ac-contact-heading-line-height: 1.1;
$ac-contact-heading-margin: 0.5rem;

.ac-contact {
    dt {
        @include element-invisible;
    }

    &__name {
        font-weight: 700;
        color: get-color(primary);
    }

    &__email {
        word-break: break-all;
    }
}

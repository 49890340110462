// Should Only contain vars
// $primary-brand-color: #000000;
$ac-blue: #003874;
$ac-yellow: #fdb515;

// Social Icon Colours
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #b00;
$vimeo: #1ab7ea;

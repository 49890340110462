// requires _main-menu.scss
$ac-mega-menu-width: 35em;

.ac-mega-menu {
    @include component-reset($body-font-color);
    @include xy-grid();
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        transition: all 0.25s linear;
        font-weight: bold;
    }

    &__nav {
        @include xy-cell(auto, false);
        padding: 0.5em;
    }

    &__nav-wrapper {
        @include xy-grid();
    }

    &__section {
        @include xy-cell(1 of 4, true, 1em, margin, top bottom left right);
    }

    &__link {
        &#{& + &} {
            @include ac-menu-nav-states;
            position: relative;
            display: block;
            width: 100%;
            min-height: 3.75em;
            margin-bottom: 0.5em;
            padding: 0.75em 1em;
            border: $medium-gray 1px solid;

            &[href] {
                color: get-color(primary);

                &:hover {
                    color: get-color(primary);
                }
            }
        }
    }

    &__card {
        @include xy-cell(50%, false)
        background-color: $light-gray;
        padding: 1em;

        + div {
            .ac-mega-menu__section {
                @include xy-cell(1 of 3, true, 1em, margin, top bottom left right);
            }
        }
    }

    &__item-link {
        color: currentColor;
        text-transform: initial;

        &#{&} {
            font-weight: 400;
        }


        &:hover {
            background-color: $light-gray;
        }
    }
}

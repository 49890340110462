$ac-main-content-max-width: 50em;

.ac-main-content {
    max-width: $ac-main-content-max-width;

    h1,
    h2,
    h3 {
        color: get-color(primary);
    }

    h2,
    h3,
    h4,
    h5 {
        margin-top: 2em;
        margin-bottom: 0.75em;
    }

    /* adjacent Headings*/
    h1 + h2,
    h1 + h3,
    h2 + h3 {
        margin-top: 1rem;
    }

    h3 + h4,
    h3 + h5,
    h2 + h4,
    h3 + h5,
    h4 + h5,
    h4 + h6 {
        margin-top: 0;
    }

    > h2 {
        margin-bottom: 0.75em;
    }

    > p {
        &:first-of-type {
            font-size: $lead-font-size;
            line-height: $lead-lineheight;
        }
    }

    &__top {
        @include xy-grid();
        @include flex-align(left, middle);

        & + h2,
        & + h3 {
            margin-top: 1rem;
        }
    }

    &__badge {
        @include tip-badge;
        font-size: 0.875em;
    }

    &__heading {
        @include xy-cell(auto, false);

        .ac-main-content__badge + & {
            padding-left: 1em;
        }
    }

    &__featured-link {
        @include ac-button;
    }

    &__image,
    &__video {
        display: block;
        margin-bottom: 2em;

        img {
            width: 100%;
            max-width: none;
        }

        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: 1rem;
        }
    }

    &__video {
        @include responsive-embed(16 by 9);

        iframe {
            ~ * {
                display: none;
            }
        }
    }

    &__quote-total {
        margin-bottom: 2rem;
        padding: 2rem;
        background-color: get-color(primary);
        color: $white;

        h3 {
            margin-top: 0;
            color: get-color(secondary);
        }
    }

    &__quote-intro {
        margin-bottom: 2rem;
    }

    &--angle-effect {
        .ac-main-content__featured-image {
            position: relative;
            overflow: hidden;

            &:after {
                @include pseudo;
                right: 0;
                bottom: 0;
                width: 50%;
                height: 200%;
                opacity: 0.6;
                transform: rotate(23deg);
                transform-origin: 0 100%;
                background-color: get-color(secondary);
            }
        }
    }

    &--has-number {
        .ac-main-content__top {
            margin-bottom: 2em;
            padding-bottom: 2em;
            border-bottom: 1px solid $medium-gray;
        }
    }

    &--full-width {
        max-width: 100%;
    }
}

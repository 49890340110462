.ac-finder {
    padding-top: 1em;
    padding-bottom: 4em;

    &__address-container,
    &__search-radius-container,
    &__search-category,
    &__actions {
        width: 100%;
        margin-bottom: 1em;

        @include breakpoint(medium) {
            display: inline-block;
            width: auto;
        }
    }

    &__search-radius-container,
    &__search-category {
        label {
            @include breakpoint(medium) {
                @include element-invisible;
            }
        }
    }

    &__search-results {
        width: 100%;
    }

    &__map {
        @include responsive-embed(1 by 1);
        width: 100%;
        border-radius: rem-calc(3);
        background-color: $light-gray;

        @include breakpoint(medium) {
            @include responsive-embed(16 by 9);
        }
    }

    &__ui {
        @include xy-grid;
        @include flex-align(left, middle);
        width: 100%;

        @include breakpoint(medium) {
            width: auto;
        }

        label {
            @include xy-cell(shrink, true, map-get($grid-column-gutter, 'small'));
            min-width: 7em;
            margin-right: 0;

            @include breakpoint(medium) {
                min-width: 0;
            }
        }

        input,
        select {
            @include xy-cell(auto, false, map-get($grid-column-gutter, 'small'));
            margin-bottom: 0;

            @include breakpoint(medium) {
                @include xy-cell(shrink, true, map-get($grid-column-gutter, 'small'));
            }

        }
    }
}

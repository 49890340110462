.ac-projectcardgrid {
    padding: 1rem;
    padding-top: 4rem;
}

.ac-projectcards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @include breakpoint(medium) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 2rem;
    }

    &__title {
        color: $ac-blue;
        font-weight: bold;
        padding-top: 1rem;
        font-size: 18px;
    }

    &__image {
    }

    &__description {
        // padding-top: 1rem;
    }

    &__link {
        color: $ac-blue;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.ac-ecreteform {
    margin-top: 4rem;
}

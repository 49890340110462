.ac-link-list {
    @include xy-grid;
    @include xy-grid-layout(2, '.ac-link-list__link');
    margin-bottom: 0.5em;
    font-size: rem-calc(14);
    font-weight: 700;

    &__link {
        display: block;
        
        &:hover {
            .ac-card & {
                color: $anchor-color-hover;
            }
        }
    }
}

@charset 'utf-8';

/* Our Mixins, no Dependancies */
@import "helpers/utility-mixins";

/* Brand Colours as Vars */
@import "colors";

/**
 *  Core foundation
 */
@import "settings";
@import "foundation";
@import "motion-ui";

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

/* Vendor */
@import "slick.scss";

$slick-font-family: inherit;
$slick-loader-path: "../img/" !default;
// $slick-arrow-color: white !default;
// $slick-dot-color: black !default;
// $slick-dot-color-active: $slick-dot-color !default;
// $slick-prev-character: "\2190" !default;
// $slick-next-character: "\2192" !default;
// $slick-dot-character: "\2022" !default;
// $slick-dot-size: 6px !default;
// $slick-opacity-default: 0.75 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 0.25 !default;

@import "slick-theme.scss";

// https://github.com/sass/sass/issues/2378#issuecomment-433868712
@function max($numbers...) {
    @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
    @return m#{i}n(#{$numbers});
}

@import "jquery.fancybox";

$hamburger-padding-x: 0;
$hamburger-padding-y: 16px * 0.25;
$hamburger-layer-border-radius: 0;
$hamburger-layer-width: 16px * 2;
$hamburger-layer-height: 16px * 0.125;
$hamburger-layer-spacing: 16px * 0.44;
$hamburger-layer-color: $white;
$hamburger-types: (arrow-r);
@import "hamburgers";

/**
 * Helpers and Additional Typography
 */
@import "helpers/utility-classes";
@import "typography";

/**
 * Elements
 */
@import "elements/button";
@import "elements/input";
@import "elements/logo";
@import "elements/filter-nav";

/**
 *  Components
 */
@import "components/header";
@import "components/top-nav";
@import "components/search";
@import "components/search-results";
@import "components/main-nav";
@import "components/mega-menu";
@import "components/sub-nav";
@import "components/link-list";
@import "components/tips-carousel";
@import "components/tip";
@import "components/banner";
@import "components/breadcrumbs";
@import "components/custom-grid";
@import "components/card";
@import "components/cta";
@import "components/intro";
@import "components/contact";
@import "components/form";
@import "components/finder";
@import "components/main-content";
@import "components/gallery";
@import "components/profile";
@import "components/footer";
@import "components/credit";
@import "components/sitemap";
@import "components/social-links";
@import "components/map";
@import "components/location";
@import "components/cookie-alert";
@import "components/projectcards";
@import "components/slider";
@import "components/slide";

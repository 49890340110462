$ac-form-breakpoint: 481;

.ac-form {
    position: relative;
    padding: 1rem 2rem;
    border: rem-calc(1) solid $medium-gray;
    background-color: $light-gray;

    label {
        .input-group {
            text-transform: initial;
        }
    }

    [type='submit'] {
        text-align: right;
    }

    [type='checkbox'] {
        margin-right: 0.5em;
    }

    .grecaptcha-badge {
        @include element-invisible;
    }

    &__step-indicator {
        margin: -1em -1em 1em;

        @include breakpoint(medium) {
            margin: 2rem 0;
        }

        button {
            width: calc(100% - 1px);
            padding: 0.5em 1em;
            background-color: $dark-gray;
            transition: background-color 0.2s linear;
            font-weight: 700;
            color: $white;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;

            &[disabled] {
                opacity: 0.1;
                background: $black;
                cursor: default;
            }

            + button {
                border-left: 1px solid $white;
            }
        }
    }

    &__steps {
        > div {
            display: none;
        }
    }

    &__step-1,
    &__step-2,
    &__step-3,
    &__step-4 {
        transition: opacity 0.5s linear;
    }

    &__footnote {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: $small-font-size;
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        display: block;
        opacity: 0;
        visibility: hidden;
        background-color: fade-out($black, 0.5);
        transition: opacity 0.25s linear;
        color: $white;

        .ac-form--step-loading & {
            z-index: inherit;
            opacity: 1;
            visibility: visible;
        }
    }

    &__loading-container {
        float: right;
        width: 4em;
        height: 4em;
        margin: 2em;

        &:before {
            @include pseudo;
            right: 6em;
            margin: 1em 2em;
            content: 'Calculating...';
            font-weight: $global-weight-bold;
        }
    }

    &__foundation {
        display: none;

        .ac-form--include-foundation & {
            display: table-row;

            @include breakpoint(small only) {
                display: block;
            }
        }
    }

    &__total {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    &__dimension-image {
        svg {
            max-width: 300px;
            margin-top: 2em;
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    &__savings-warning {
        h5 {
            color: $white;
        }

        .close-button {
            color: $white;
        }
    }

    &__highlight {
        &#{&} {
            background-color: $dark-gray;

            @include breakpoint(small only) {
                margin-right: -1em;
                margin-left: -1em;
            }

            label,
            span,
            [readonly] {
                color: $white;
            }

            td {
                &:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }

    &__disclaimer {
        font-size: 0.875rem;
    }

    &--hide-savings-warning {
        .ac-form__savings-warning {
            display: none;
        }
    }

    &--step-1 {
        .ac-form__step-1 {
            display: block;
        }

        [name='step1Indicator'] {
            background-color: get-color(secondary);
            color: $black;
        }
    }

    &--step-2 {
        .ac-form__step-2 {
            display: block;
        }

        [name='step2Indicator'] {
            background-color: get-color(secondary);
            color: $black;
        }
    }

    &--step-3 {
        .ac-form__step-3 {
            display: block;
        }

        [name='step3Indicator'] {
            background-color: get-color(secondary);
            color: $black;
        }
    }

    &--step-4 {
        .ac-form__step-4 {
            display: block;
        }

        [name='step4Indicator'] {
            background-color: get-color(secondary);
            color: $black;
        }
    }

    &--step-1-leaving {
        .ac-form__step-1 {
            opacity: 0;
        }

        .ac-form__step-2,
        .ac-form__step-3,
        .ac-form__step-4 {
            position: absolute;
            opacity: 0;
        }
    }

    &--step-2-leaving {
        .ac-form__step-2 {
            opacity: 0;
        }

        .ac-form__step-1,
        .ac-form__step-3,
        .ac-form__step-4 {
            position: absolute;
            opacity: 0;
        }
    }

    &--step-3-leaving {
        .ac-form__step-3 {
            opacity: 0;
        }

        .ac-form__step-1,
        .ac-form__step-2,
        .ac-form__step-4 {
            position: absolute;
            opacity: 0;
        }
    }

    &--step-4-leaving {
        .ac-form__step-4 {
            opacity: 0;
        }

        .ac-form__step-1,
        .ac-form__step-2,
        .ac-form__step-3 {
            position: absolute;
            opacity: 0;
        }
    }

    &--ready-floor-calculator {
        @include breakpoint(small only) {
            padding: 1em;
        }

        table, thead, tbody, th, td, tr {
            @include breakpoint(small only) {
                display: block;
            }
        }

        td {
            &:empty {
                @include breakpoint(small only) {
                    display: none;
                }
            }

            + td {
                @include breakpoint(small only) {
                    padding-top: 0;
                }
            }

            &:first-child {
                @include breakpoint(small only) {
                    margin-bottom: 1em;
                    border-left: 0;
                    background-color: $dark-gray;
                }

                label {
                    @include breakpoint(small only) {
                        color: $white;
                        text-align: center;
                    }
                }
            }
        }


        [readonly] {
            border-color: transparent;
            background-color: transparent;
        }

        [readonly],
        [data-currency] {
            text-align: right;
        }

        [name='PumpingCost'] {
            + span {
                display: none;
            }
        }

        tr {
            background-color: $light-gray;
        }

        td {
            .input-group {
                margin-bottom: 0;
            }

            &:first-child {
                margin-right: -1em;
                margin-left: -1em;
                border-right: 1px $medium-gray solid;
                text-align: right;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    &--no-border {
        border: 0;
        background-color: transparent;
    }

    &--include-foundation {
        [name='PumpingCost'] {
            + span {
                display: flex;
            }
        }
    }
}

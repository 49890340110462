.ac-intro {
    @include xy-grid()
    margin-right: -1rem;
    margin-left: -1rem;

    &__image,
    &__content {
        @include xy-cell(1 of 1, true, $grid-column-gutter, margin, top right left bottom);

        @include breakpoint(medium) {
            @include xy-cell(1 of 2, true, $grid-column-gutter, margin, top right left bottom);
        }
    }

    &__image {
        display: block;
        
        @include breakpoint(large) {
            @include flex-order(2);
        }

        img {
            width: 100%;
        }
    }

    &__link {
        @include ac-button;
    }
}

$ac-slide-background-color: rgba(get-color(primary), 0.6);

.ac-slide {
    @include component-reset();
    position: relative;
    background-color: $light-gray;
    overflow: hidden;
    height: 0;
    padding: ratio-to-percentage(5 by 1);

    .svg-icon {
        color: $white;
    }

    &__container {
        @include xy-grid-container;
        margin: 1em auto;

        @include breakpoint(medium) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: auto;
            text-align: center;
        }
    }

    &__wrapper {
        @include xy-grid();
        @include flex-align(right, middle);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__overlay {
        opacity: 0;

        @include breakpoint(medium) {
            @include xy-cell(shrink);
            opacity: 1;
            position: relative;
            padding: 2em;
            background: $ac-slide-background-color;
            box-shadow: $global-shadow-close;
            text-align: right;
            max-width: 75%;
        }

        @include breakpoint(large) {
            max-width: 55%;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__caption {
        margin-bottom: 1em;
        line-height: 1.4;
        color: $white;
    }
}

$ac-vertical-grid-height: 1em * $paragraph-lineheight;
$ac-text-top-margin: 1em * $paragraph-lineheight / 4;
$ac-profile-image-width:  rem-calc(170);
$ac-profile-image-height: ($ac-vertical-grid-height * 7) + $ac-text-top-margin;

.ac-profile {
    max-width: $ac-main-content-max-width;

    & + &  {
        margin-top: 2em;
        padding-top: 2em;
        border-top: $medium-gray rem-calc(1) solid;

        @include breakpoint(large) {
            margin-top: 4em;
            padding-top: 3em;
        }
    }

    &__name {
        font-size: 1.8em;
        font-weight: 300;
    }

    &__image {
        @include breakpoint(480) {
            float: left;
            width: $ac-profile-image-width;
            height: $ac-profile-image-height;
            margin-top: $ac-text-top-margin;
            margin-right: 1em;
            overflow: hidden;
        }

        @include breakpoint(medium) {
            width: rem-calc(272);
            height: rem-calc(328);
        }

        img {
            width: 100%;
        };
    }
}
$ac-custom-grid-gutter: $grid-column-gutter;
$ac-custom-grid-selector: '.ac-custom-grid__item';

.ac-custom-grid {
    &__wrapper {
        @include xy-grid()
        @include xy-gutters($negative: true);
    }

    &__top {
        @include xy-grid()
        @include flex-align(justify, middle);
        @include xy-gutters($negative: true);
    }

    &__readmore {
        @include xy-cell(shrink, true, $grid-column-gutter);
    }

    &__heading {
        @include xy-cell(auto, true, $grid-column-gutter, margin, top right left bottom);
        color: get-color(primary);
    }

    &__item {
        @include xy-cell($size: 1 of 1, $gutter-position: top right left bottom);
        position: relative;

        @include breakpoint(medium) {
            @include xy-cell($size: 1 of 2, $gutter-position: top right left bottom);
        }
    }

    &--3-up {
        .ac-custom-grid__item {
            @include breakpoint(550) {
                @include xy-cell($size: 1 of 2, $gutter-position: top right left bottom);
            }

            @include breakpoint(820) {
                @include xy-cell($size: 1 of 3, $gutter-position: top right left bottom);
            }
        }
    }

    &--4-up {
        .ac-custom-grid__item {
            @include breakpoint(550) {
                @include xy-cell($size: 1 of 2, $gutter-position: top right left bottom);
            }

            @include breakpoint(820) {
                @include xy-cell($size: 1 of 3, $gutter-position: top right left bottom);
            }

            @include breakpoint(1024) {
                @include xy-cell($size: 1 of 4, $gutter-position: top right left bottom);
            }
        }
    }

    &--1-up {
        .ac-custom-grid__item {
            @include breakpoint(medium) {
                @include xy-cell($size: 1 of 1, $gutter-position: top right left bottom);
            }
        }
    }

    &--contacts-3-up {
        .ac-custom-grid__item {
            @include breakpoint(550) {
                @include xy-cell($size: 1 of 2, $gutter-position: top right left bottom);
            }

            @include breakpoint(xlarge) {
                @include xy-cell($size: 1 of 3, $gutter-position: top right left bottom);
            }
        }
    }

    &--ctas-4-up {
        .ac-custom-grid__item {
            margin-top: 0;

            @include breakpoint(medium) {
                @include xy-cell($size: 1 of 3, $gutter-position: top right left bottom);
            }

            @include breakpoint(xlarge) {
                @include xy-cell($size: 1 of 4, $gutter-position: top right left bottom);
            }

            &:nth-child(4n) {
                &:last-child {
                    @include breakpoint(medium) {
                        display: none;
                    }

                    @include breakpoint(xlarge) {
                        display: block;
                    }
                }
            }
        }
    }

    &--ctas-1-up {
        margin-top: 0.5em;

        .ac-custom-grid__item {

            @include breakpoint(large) {
                @include xy-cell(1 of 1, true, $grid-column-gutter, margin, right left, null, false);
                margin-top: 0;
            }

            &:nth-child(4n) {
                &:last-child {
                    @include breakpoint(large) {
                        display: none;
                    }
                }
            }
        }
    }

    &--1-max {
        .ac-custom-grid__item {
            + .ac-custom-grid__item  {
                display: none;
            }
        }
    }

    &--2-max {
        .ac-custom-grid__item {
            &:nth-child(1n + 3) {
                display: none;
            }
        }
    }
}

.ac-search {
    @include xy-grid();

    &__label {
        @include element-invisible;
    }

    &__input {
        @include xy-cell(auto, false);
        margin-bottom: 0;
        height: rem-calc(40);

        @include breakpoint(large) {
            height: 2em;
        }
    }

    &__button {
        @include xy-cell(shrink, false);
        @include button();
        margin-bottom: 0;
        padding: 0 0.5em;
        border: 0;

        svg {
            display: block;
            width: 1.5em;
            height: 1.5em;
        }
    }
}

$ac-location-max-width: 50em;

.ac-location {
    max-width: $ac-location-max-width;

    h1,
    h2,
    h3 {
        color: get-color(primary);
    }

    h2,
    h3,
    h4,
    h5 {
        margin-top: 2em;
        margin-bottom: 0.75em;
    }

    h1 + h2,
    h1 + h3,
    h2 + h3 {
        margin-top: 1rem;
    }

    h3 + h4,
    h3 + h5,
    h2 + h4,
    h3 + h5,
    h4 + h5,
    h4 + h6 {
        margin-top: 0;
    }

    > h2 {
        margin-bottom: 0.75em;
    }

    > p {
        &:first-of-type {
            font-size: $lead-font-size;
            line-height: $lead-lineheight;
        }
    }

    &__heading {
        @include xy-cell(100%, false);
    }

    &__details-wrapper {
        @include xy-grid();

        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: 1rem;
        }
    }

    &__meta,
    &__map {
        @include xy-cell(100%, false);

        @include breakpoint(medium) {
            @include xy-cell(1 of 2, false);
        }
    }
}

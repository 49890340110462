.ac-footer {
    border-top: rem-calc(1) $medium-gray solid;
    background: linear-gradient($light-gray, darken($light-gray, 6%));
    text-align: center;

    a {
        font-weight: bold;
    }

    &__container {
        @include xy-grid-container;
    }

    &__wrapper {
        @include xy-grid();
        @include flex-align(justify, middle);
    }

    &__top,
    &__bottom {
        @include xy-cell(100%, true, $grid-column-gutter, margin, top bottom left right);

        @include breakpoint(large) {
            @include xy-cell(shrink, true, $grid-column-gutter, margin, top bottom left right);
        }
    }

    &__top {
        @include breakpoint(large) {
            text-align: left;
        }
    }

    &__bottom {
        @include breakpoint(large) {
            text-align: right;
        }

        svg {
            @include breakpoint(large) {
                display: block;
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    &__social {
        @include xy-grid();
        @include flex-align(center, middle);
        width: 100%;
        margin-bottom: 0.5em;
    }

    &__nav {
        margin-bottom: 0.5em;
    }

    &__logo {
        margin: 0 auto;
        text-align: center;

        svg {
            width: 8em;
        }
    }

    &__credit,
    &__group-declaration {
        margin-top: 1em;
        font-size: rem-calc(13);
        font-weight: bold;
    }

    &__group-declaration,
    &__credit,
    &__nav {
        text-shadow: 1px 1px 1px $white;
    }
}

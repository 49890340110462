.ac-slider {
    position: relative;

    &__container {
        &#{&} {
            margin-bottom: 2.5rem;
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease;

            &.slick-initialized {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__next,
    &__prev {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        transition: all 0.25s $global-ease;
        cursor: pointer;

        svg {
            width: 1.5em;
            height: 1.5em;
            color: $white;
        }

        &.slick-disabled {
            opacity: 0;
            cursor: default;
        }
    }

    &__next {
        right: 1.5em;
    }

    &__prev {
        left: 1.5em;
    }

    &__dots {
        li {
            button:before {
                font-size: 5rem;
                color: $light-gray;
                opacity: 1;
            }

            &.slick-active {
                button:before {
                    color: $ac-yellow;
                }
            }
        }
    }
}

@mixin tip-badge {
    @include xy-cell(shrink, false);
    padding: 1em;
    background-color: get-color(secondary);
    font-weight: bold;
    line-height: 1;
    color: $white;
    text-align: center;

    &-label {
        font-size: 0.875em;
        color: get-color(primary);
        text-transform: uppercase;

    }

    &-number {
        display: block;
        margin-top: 0.25rem;
        font-size: 3.5em;
    }
}

.ac-tip {
    @include xy-grid();
    background-color: $white;
    box-shadow: $global-shadow-close;
    font-weight: bold;


    &__badge {
        @include tip-badge;
    }

    &__content {
        @include xy-cell(auto, false);
        padding: 1em 1.5em;
    }

    &__title {
        font-weight: inherit;
        color: get-color(primary);
    }

    &__link {
        font-size: 0.875em;
        text-transform: uppercase;
    }
}

.ac-tips-carousel {
    @include xy-grid();
    background-color: $light-gray;

    &__top,
    &__body {
        @include xy-cell(1 of 1, false);
    }

    &__top {
        margin: 0.5em 1em 0;
        text-align: center;

        @include breakpoint(medium) {
            @include xy-cell(1 of 4, true, $grid-column-gutter, margin, top right left bottom);
            text-align: left;
        }
    }

    &__body {
        width: 100%;
        margin-top: 0;

        @include breakpoint(medium) {
            @include xy-cell(3 of 4, true, $grid-column-gutter, margin, top right left bottom);
        }
    }

    &__heading {
        margin-top: 0.25em;
        margin-bottom: 0.5em;
        color: get-color(primary);
    }

    &__list {
        padding: 0 2.5em;

        @include breakpoint(medium) {
            padding: 0 5em;
        }
    }

    &__item {
        display: inline-block;
        padding: 1em;
    }

    &__next,
    &__prev {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        transition: all 0.25s $global-ease;
        cursor: pointer;

        svg {
            width: 1.5em;
            height: 1.5em;
        }

        &.slick-disabled {
            opacity: 0;
            cursor: default;
        }
    }

    &__next {
        right: 1.5em;
    }

    &__prev {
        left: 1.5em;
    }

    &--3up {
        background: none;

        .ac-tips-carousel__top ,
        .ac-tips-carousel__body {
            @include xy-cell(1 of 1, false);
            margin: 0.5rem 0;
        }

        .ac-tips-carousel__body {
            background-color: $light-gray;
        }

        .ac-tips-carousel__next {
            right: 1em;

            @include breakpoint(medium) {
                right: 2.5em;
            }
        }

        .ac-tips-carousel__prev {
            left: 1em;

            @include breakpoint(medium) {
                left: 2.5em;
            }
        }
    }
}

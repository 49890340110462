/**
* String Replace
*/

@function str-replace($string, $search, $replace: '') {
    $string: inspect($string);
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

/**
* Font Face: https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
*/
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $extmods: ( eot: '?', svg: '#' + str-replace($name, ' ', '_') );
    $formats: ( otf: 'opentype', ttf: 'truetype' );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

/**
* pseudo Element Helper
*/
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    position: $pos;
    display: $display;
    content: $content;
}

/**
* Resets Component fontsize, color.
*/
@mixin component-reset($color: $black, $font-size: 1rem, $background-color: transparent) {
    background-color: $background-color;
    font-size: $font-size;
    color: $color;

    &[href],
    a {
        color: currentColor;

        &:hover {
            color: currentColor;
            text-decoration: none;
        }
    }
}

/**
* Single Line truncation
*/
@mixin truncate($truncation-boundary: none) {
    max-width: $truncation-boundary;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/**
* Produces a group of color classes at various tints, given a hex code
*/
@mixin color-classes($base, $name: 'primary') {
    $scales: (80%, 60%, 40%, 20%);

    .#{$name} {
        background-color: $base;
        @each $scale in $scales {
            &--#{str-replace($scale, '%', '')} {
                background-color: mix($base, $white, $scale);
            }
        }
    }
}

/**
* Generate classes [breakpoint]-[property]-[value]. See Helper Classes for usage
* e.g. .small-pt-2rem
*/
@mixin make-space($values, $prefixes, $breakpoints) {
    @each $breakpoint-name, $breakpoint-value in $breakpoints {
        // if xs value = 0, set it global without media queries
        @if($breakpoint-value == 0) {
            @each $attr-short, $attr-long in $prefixes {
                @each $value in $values {
                    .#{$breakpoint-name + '-' + $attr-short + '-' + str-replace($value, '.', '')} {
                        #{$attr-long}: #{$value};
                    }
                }
            }
        }
        // breakpoint values that not equal to 0
        @else {
            @media screen and (min-width: $breakpoint-value) {
                @each $attr-short, $attr-long in $prefixes {
                    @each $value in $values {
                        .#{$breakpoint-name + '-' + $attr-short + '-' + str-replace($value, '.', '')} {
                            #{$attr-long}: #{$value};
                        }
                    }
                }
            }
        }
    }
}


/**
* Computes a CSS calc function that betweens a value from
* A to B over viewport-width A to viewport-width B.
* Requires a media query to cap the value at B.
*/
@function between($to, $from, $to-width, $from-width) {
    $slope: ($to - $from) / ($to-width - $from-width);
    $base: $from - $slope * $from-width;
    @return calc(#{$base} + #{100vw * $slope});
}
@function rem-between($to, $from, $to-width, $from-width) {
    $slope: (strip-unit($to) - strip-unit($from)) / (rem-calc($to-width) - rem-calc($from-width));
    $base: $from - $slope * rem-calc($from-width);
    @return calc(#{$base} + #{100vw * strip-unit($slope)});
}

/**
* Generates multiline ellipsed text
*/

// Deprecated > Polyfilling
// sass-lint:disable mixin-name-format
@mixin multilineTextWithEllipsis ($font-size: 1rem, $line-height: $paragraph-lineheight, $lines-to-show: 2) {
    @include multiline-truncate ($font-size, $line-height, $lines-to-show)
}
// sass-lint:enable mixin-name-format

@mixin multiline-truncate ($font-size: 1rem, $line-height: $paragraph-lineheight, $lines-to-show: 2) {
    // sass-lint:disable-block no-vendor-prefixes, no-duplicate-properties
    display: -webkit-box;
    display: block;
    -webkit-box-orient: vertical;
    height: $font-size*$line-height*$lines-to-show;
    margin: 0 auto;
    overflow: hidden;
    font-size: $font-size;
    line-height: $line-height;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines-to-show;
}

/**
* A replacement for box-shadow to produce more performant animations
*/
@mixin box-shadow-replacer($x: 0, $y: 2px, $blur: 4px, $spread: 0, $color: rgba(0, 0, 0, 0.2)) {
    // sass-lint:disable-block force-pseudo-nesting
    position: relative;
    overflow: visible;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        display: block;
        filter: blur($blur);
        transform: translate($x, $y);
        background: $color;
        content: '';
    }

    &[href]:before {
        opacity: 1;
        transition: all 300ms $global-easing;
    }

    &[href]:hover:before {
        opacity: 1;
        transform: translate($x, $y) scale(1.025);
    }
}

@mixin slick-controls($arrow-color: $white, $dot-color: $white) {
    $dot-color-active: $dot-color !default;
    $dot-size: 6px !default;
    $opacity-default: 0.75 !default;
    $opacity-on-hover: 1 !default;
    $opacity-not-active: 0.25 !default;

    /* Arrows */
    .slick-next,
    .slick-prev {
        position: absolute;
        top: 50%;
        z-index: 20;
        display: block;
        width: 2.5em;
        height: 2.5em;
        padding: 0;
        transform: translate(0, -50%);
        outline: none;
        border: 0;
        background: transparent;
        line-height: 1;
        color: $arrow-color;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }

        &.slick-disabled {
            opacity: $opacity-not-active;
        }
    }

    .slick-prev {
        left: 1em;
    }

    .slick-next {
        right: 1em;
    }

    /* Dots */
    .slick-dots {
        position: absolute;
        bottom: 1em;
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;

        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                outline: none;
                border: 0;
                border-radius: 50%;
                background: $dot-color;
                font-size: 0;
                line-height: 0;
                color: transparent;
                cursor: pointer;

                &:focus,
                &:hover {
                    outline: none;
                }
            }

            &.slick-active {
                button {
                    opacity: $opacity-default;
                    color: $dot-color-active;
                }
            }
        }
    }
}

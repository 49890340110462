$ac-cta-base-size: 1rem;
$ac-cta-line-height: 1.4;
$ac-cta-base-color: $body-font-color;
$ac-cta-base-color-inverted: $white;
$ac-cta-background-color: $light-gray;
$ac-cta-background-color-inverted: $black;
$ac-cta-content-padding: 1rem;
$ac-cta-title-size: 1.4em;
$ac-cta-title-line-height: 1.2;

.ac-cta {
    @include component-reset(get-color(primary), $ac-cta-base-size, $light-gray);
    text-transform: initial;

    &__wrapper {
        display: block;
        border-top: 0.125em solid $dark-gray;
        transition: opacity 0.25s linear;

        &:hover {
            opacity: 0.8;
        }
    }

    &__top {
        @include xy-grid(horizontal, false);
        @include flex-align(justify, top);
    }

    &__heading {
        @include xy-cell(auto, false);
        @include flex-order(1);
        margin: 1rem 1rem 0.5rem;
        font-size: 1.25em;
        font-weight: bold;
        line-height: 1.4;
    }

    &__icon {
        @include xy-cell(shrink, false);
        @include flex-order(2);
        position: relative;
        width: 4.5em;
        height: 4.5em;
        background: linear-gradient(get-color(secondary), darken(get-color(secondary), 6%));

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            max-width: 4.5em;
            max-height: 4.5em;
            margin: auto;
            padding: 0.5em;
        }
    }

    &__description {
        display: none;
        color: $body-font-color;

        @include breakpoint(medium) {
            @include multiline-truncate($ac-cta-base-size, $ac-cta-line-height, 3);
            margin: 0.5rem 1rem 1rem;
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: rem-calc(7) 1em rem-calc(9);
        background-color: $dark-gray;
        transition: 0.2s background-color $global-ease;
        font-size: 0.875em;
        font-weight: bold;
        line-height: 1;
        color: $white;
        text-align: center;
        text-transform: uppercase;
    }

    &__link-text {
        display: inline-block;
        color: currentColor;
    }

    &__link-icon {
        margin-left: 0.5em;
        font-size: 1.2em;
        line-height: 1;
    }

    &__image {
        .ac-mega-menu & {
            display: none;

            @include breakpoint(xlarge) {
                display: block;
            }
        }
    }
}

@mixin ac-menu-nav-states {
    position: relative;

    .current > &,
    .active > &,
    :hover > & {
        background-color: $light-gray;
        text-shadow: 1px 1px 1px $white;
    }

    &:after,
    &:before {
        @include pseudo();
        opacity: 0;
        transform: scale(0, 1);
        transition: opacity 0.25s linear, transform 0.3s ease-out;

        :hover > & {
            opacity: 1;
            transform: scale(1, 1);
        }
    }

    &:after {
        top: auto;
        right: 0;
        bottom: -0.25rem;
        left: 0;
        height: 0.25rem;
        background-color: get-color(secondary);
    }

    &:before {
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        transform: scale(1, 1);
        background-color: $medium-gray;

        .current > &,
        .active > & {
            opacity: 1;
            transform: scale(1, 1);
        }
    }
}

.ac-main-nav {
    @include xy-grid-container;
    @include component-reset($body-font-color);
    position: static;
    font-weight: bold;
    text-transform: uppercase;

    &__mega-menu {
        box-shadow: $global-shadow-far;
        display: none;

        &#{& + & + & + &} {
            right: 0;
            width: 100%;
            margin-top: 0.25em;
            margin-left: 0;
        }

        &.js-dropdown-active {
            animation-duration: 0.5s;
            animation-name: custom-1;
            animation-timing-function: cubic-bezier(0.11, 0.92, 0.05, 0.99);
        }
    }

    &__list,
    &__item {
        position: static;
    }

    &__link {
        &#{& + &} {
            @include component-reset($white);
            @include ac-menu-nav-states;
            display: block;
            padding-top: 0.75em;
            padding-bottom: 0.75em;
            transition: all 0.25s linear;
        }
    }

    &__link-text {
        .current > a > &,
        .active > a > & {
            font-weight: bold;
        }
    }

    &__child-link {
        position: relative;
    }

    &__icon,
    &__child-icon {
        position: absolute;
        top: 0.5em;
        right: 1em;

        .is-submenu-parent-item & {
            display: none;
        }
    }

    &__close-button {
        font-size: 2em;
        margin: 0.75rem;
        color: $body-font-color;

        svg {
            width: 1em;
            height: 1em;
        }
    }

    &--horizontal {
        &#{&} {
            margin-right: -2em;

            .ac-main-nav__link {
                color: currentColor;
            }
        }

        .ac-main-nav__icon,
        .ac-main-nav__child-icon {
            display: none;
        }

        .ac-main-nav__grandchild-link {
            font-weight: 400;
        }
    }

    &--vertical {
        padding: 0;

        &#{&} {
            .ac-main-nav__link {
                color: currentColor;
            }

            .ac-main-nav__link-text,
            .ac-main-nav__child-text {
                display: inline-block;
                padding-right: 3em;
            }

            .ac-main-nav__child-link,
            .ac-main-nav__grandchild-link {
                margin-left: 1em;
                font-weight: bold;
                color: $body-font-color;
                text-transform: initial;
            }

            .is-submenu-parent-item {
                a {
                    margin-right: 1em;
                    margin-left: 1em;
                    border: $medium-gray 1px solid;
                    font-weight: bold;
                    color: get-color(primary);
                    text-transform: uppercase;
                }
            }
        }
    }

    .js-drilldown-back {
        font-weight: 600;
        color: $black;
    }
}

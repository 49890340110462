// required ac-main-content

.ac-gallery {
    @include component-reset();

    .ac-main-content + & {
        max-width: $ac-main-content-max-width;
    }

    &__wrapper {
        @include xy-grid;
        @include xy-gutters($negative: true);
    }

    &__heading {
        margin-top: 2em;
        color: get-color(primary);
    }

    &__item {
        @include xy-cell($size: 1 of 2, $gutter-position: top right bottom left);
        transition: opacity 0.25s linear;
        cursor: zoom-in;

        @include breakpoint(medium) {
            @include xy-cell($size: 1 of 3, $gutter-position: top right bottom left);
        }

        &:hover {
            opacity: 0.9;
        }
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__title {
        font-weight: 700;
        color: get-color(primary);
    }

    &__description {
        font-weight: 300;
        color: $black;
    }

    &__caption {
        @include multiline-truncate;
        margin-bottom: 0;
    }

    &--magazine-style {
        .ac-gallery__title {
            @include element-invisible;
        }
        .ac-gallery__item {
            @include breakpoint(medium) {
                @include xy-cell($size: 1 of 2, $gutter-position: top right bottom left);
            }
        }
    }
}

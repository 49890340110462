// requires _main-menu.scss

.ac-sub-nav {
    @include component-reset;

    a {
        transition: all 0.25s linear;
        font-weight: bold;
    }

    &__heading {
        width: 100%;
        margin-bottom: 0;
        padding: $global-menu-padding;
        margin-bottom: 0.25em;
        text-transform: uppercase;
        line-height: 1;
        font-weight: 700;
        color: $black;
        background-color: $light-gray;
    }

    &__item {
        position: relative;
        margin-bottom: 0.25em;
        color: get-color(primary);
    }

    &__link {
        @include ac-menu-nav-states;
        border: $medium-gray 1px solid;

        + ul {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }

    &__child-item {
        &.current {
            a {
                color: get-color(primary);
                border-bottom: get-color(primary) 1px solid;
            }
        }
    }

    &__child-link {
        &#{&} {
            display: inline-block;
            margin-left: 1em;
            padding: 0.25em 0;
            font-weight: normal;
            color: $body-font-color;
            text-transform: initial;
        }
    }
}

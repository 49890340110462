.ac-sitemap {
    &__menu {
        padding-left: 1.25em;
    }

    &__item,
    &__child-item,
    &__grandchild-item {
        list-style-type: disc;
    }

    a {
        font-weight: 700;
    }

    &__child-menu,
    &__grandchild-menu {
        margin-left: 1.25em;
    }

    &__link {
        text-transform: uppercase;
    }

    &__grandchild-link,
    &__child-link {
        text-transform: none;
    }
}

.ac-header {
    @include component-reset($white, 0.825rem, $white);
    position: relative;
    border-bottom: rem-calc(5) $light-gray solid;

    @include breakpoint(medium) {
        font-size: 1rem;
    }

    &__container {
        @include xy-grid-container;

        @include breakpoint(large) {
            margin-top: 1em;
        }
    }

    &__wrapper {
        @include xy-grid();
        @include flex-align(left, top);

        @include breakpoint(large) {
            @include flex-align(justify, middle);
            position: relative;
        }
    }

    &__logo {
        @include xy-cell(100%, false);
        display: block;
        margin-top: 1.25em;
        margin-bottom: 0.5em;
        font-size: 3rem;
        line-height: 1;

        @include breakpoint(large) {
            @include xy-cell(shrink, true, $grid-column-gutter, margin, top bottom);
            @include flex-order(1);
            margin-right: auto;
            font-size: 5em;
        }

        svg {
            display: block;
            width: 5.08em;
            height: 1.1em;
            margin: auto;
        }
    }

    &__search {
        position: absolute;
        top: 0;
        right: 5em;
        left: 0;
        z-index: 10;
        display: none;

        @include breakpoint(large) {
            @include xy-cell(full, true, $grid-column-gutter, margin, top right left bottom, null, false);
            position: static;
            display: block;
            width: 100%;
            max-width: 30em;
            margin: 0;
        }

        &.expanded {
            display: block;
        }
    }

    &__close-search {
        @include breakpoint(medium) {
            display: none;
        }
    }

    &__desktop-ui {
        @include breakpoint(large) {
            @include xy-cell(shrink, true, $grid-column-gutter, margin, top  bottom, null, false);
            @include flex-order(2);
        }
    }

    &__nav-toggle,
    &__search-toggle,
    &__phone {
        position: absolute;
        display: block;
    }

    &__search-toggle {
        right: 1em;

        .ac-header--search-expanded & {
            opacity: 0.5;
        }

        @include breakpoint(large) {
            display: none;
        }
    }

    &__search-toggle,
    &__phone {
        padding: 0.5em;
        background: linear-gradient(get-color(secondary), darken(get-color(secondary), 8%));
        line-height: 1;
        color: currentColor;

        svg {
            width: 2em;
            height: 2em;
        }
    }

    &__phone {
        right: 5em;
        @include breakpoint(large) {
            position: static;
            padding-right: 0;
            background: transparent;
        }

        svg {
            @include breakpoint(large) {
                display: none;
            }
        }
    }

    &__phone-text,
    &__phone-number {
        @include element-invisible;
        font-size: 1.4em;
        color: get-color(primary);
        text-align: right;

        @include breakpoint(large) {
            @include element-invisible-off;
            display: block;
        }
    }

    &__phone-text {
        margin-bottom: 0.25em;
        font-size: 2em;
    }

    &__nav-toggle {
        left: 1em;

        button {
            padding: 0.75em 0.5em;
            background: linear-gradient(get-color(secondary), darken(get-color(secondary), 8%));
            line-height: 1;

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    &__menu-container {
        @include xy-grid-container;
    }

    &__menu-wrapper {
        @include xy-grid();
        @include flex-align(right, top);
    }

    &__menu {
        @include xy-cell(shrink, false);
        display: none;

        @include breakpoint(large) {
            @include flex-order(3);
            position: static;
            display: block;
            margin-left: auto;
        }
    }
}
